/* @flow */

import * as React from 'react'
import { DemonstrationThankTemplate } from '../templates'

type Props = {
  location: {
    href: string,
  },
}

export default function Thank(props: Props) {
  const { location } = props
  return <DemonstrationThankTemplate url={location.href} name="Wino" title="Merci !" />
}
